@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }
}

.fade-in-1 {
  opacity: 0;
  transform: translateY(5px);
  animation: 800ms fade-in 200ms ease-in-out forwards;
}

.fade-in-2{
  opacity: 0;
  transform: translateY(5px);
  animation: 800ms fade-in 400ms ease-in-out forwards;
}

.fade-in-3{
  opacity: 0;
  transform: translateY(5px);
  animation: 800ms fade-in 600ms ease-in-out forwards;
}

.fade-in-4{
  opacity: 0;
  transform: translateY(5px);
  animation: 800ms fade-in 800ms ease-in-out forwards;
}

.wave {
  display: inline-block;
  animation: 1000ms wave 600ms ease-in-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wave {
  0%, 100% {
    transform: rotate(0deg)
  }
  20%, 60% {
    transform: rotate(5deg)
  }
  40%, 80% {
    transform: rotate(-5deg)
  }
}